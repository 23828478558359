<template>
  <form id="newRecord" @submit.prevent="saveOrUpdateFrequency">
    <c-422-errors :errors="errors" />
    <div class="mb-3">
      <label for="frequency" class="form-label">Nombre:</label>
      <input
        v-model="frequency.frecuency"
        class="form-control"
        id="frequency"
        required
      />
    </div>
    <c-primary-button class="w-100" :disabled="loading">
      Guardar
    </c-primary-button>
  </form>
</template>
<script>
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import C422Errors from "@/components/C422Errors";
import { create, update, find } from "@/services/frequencies";
import { showNotification } from "@/services/notification";

export default {
  components: {
    C422Errors,
    CPrimaryButton,
  },
  data() {
    return {
      errors: {},
      loading: false,
      frequency: {
        frecuency: "",
      },
      frequencyId: this.$route.params.id,
    };
  },
  mounted() {
    this.getFrequency();
  },
  methods: {
    getFrequency() {
      if (!this.frequencyId) {
        return;
      }
      find(this.frequencyId).then((response) => {
        this.frequency = response.data;
      });
    },
    saveOrUpdateFrequency() {
      if (this.frequencyId) {
        this.updateFrequency();
      } else {
        this.saveFrequency();
      }
    },
    saveFrequency() {
      create(this.frequency).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/frecuencias");
      });
    },
    updateFrequency() {
      update(this.frequencyId, this.frequency).then(() => {
        showNotification(201);
        this.$router.push("/admin/configuraciones/frecuencias");
      });
    },
  },
};
</script>
